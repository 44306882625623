import React, { useState } from 'react';

import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import './styles.scss'

import { connect } from 'react-redux';
import { signinUser, API_LOCATION } from '../../actions';
import FacebookLogin from 'react-facebook-login';
import { facebookAppID } from '../../settings'

import { arrowLeftA } from 'react-icons-kit/ionicons/arrowLeftA';
import { arrowLeftB } from 'react-icons-kit/ionicons/arrowLeftB';
import { arrowLeftC } from 'react-icons-kit/ionicons/arrowLeftC';
import Icon from 'react-icons-kit';
import _ from 'lodash';

import { withTranslation, useTranslation } from 'react-i18next'
import UserForm from '../profile_form'

// STEPS
const LOGIN_BUTTONS  = 0,
	  REGISTER_FORM  = 1,
	  LOGIN_FORM     = 2,
	  LOST_PWD_FORM  = 3,
	  FILL_INFO_FORM = 4;

function RegisterForm({onSuccess}){
	const [username, setUsername] = useState('');
	const [email,    setEmail] = useState('');
	const [pwd,           setPwd] = useState('');
	const { t, i18n } = useTranslation();

	const onSubmit = e => {
		e.preventDefault();
		// TRY TO REGISTER

		if(username.length > 3 && pwd.length > 3 && /\S+@\S+\.\S+/.test(email) ){
			axios.post(`${API_LOCATION}/register/`,{username, password:pwd, email}).then(({data}) => {
				alert(t("login.email.account_created"))
				onSuccess(data); 
			}).catch(({response:{data}}) => { alert( data[Object.keys(data)[0]] ) })
		}else{
			alert(t("login.email.error"))
		}
	}

	return <form onSubmit={onSubmit} className='login_form'>
		<div><label>Email</label><input required placeholder="me@mail.com" type='email' onChange={e=>setEmail(e.target.value)} value={email}/></div>
		<div><label>Username</label><input required placeholder="cool_username" type='text' onChange={e=>setUsername(e.target.value)} value={username}/></div>
		<div><label>Password</label><input required placeholder="*****" type='password' onChange={e=>setPwd(e.target.value)} value={pwd}/></div>
		<button type="submit">{t('login.submit.register')}</button>
	</form>
}

function ForgotPWDForm({onSuccess}){
	const [email,    setEmail] = useState('');
	const { t, i18n } = useTranslation();
  
	const onSubmit = e => {
		e.preventDefault();
		// TRY TO RESET PWD
		if( /\S+@\S+\.\S+/.test(email) ){
			axios.post(`${API_LOCATION}/reset_password/`,{email}).then(({data}) => {
				alert( t('login.reset_password_success') );
				onSuccess(data); 
			}).catch(({response:{data}}) => { 
				if(data.email){
					alert( t('login.reset_password_error.email') );
				}else{
					alert( t('login.reset_password_error') );	
				}
			})
		}else{
			alert(t("login.email.error"))
		}
	}

	return <form onSubmit={onSubmit} className='login_form'>
		<div><input required placeholder="me@mail.com" type='email' onChange={e=>setEmail(e.target.value)} value={email}/></div>
		<button type="submit">{t('login.submit.recover')}</button>
	</form>
}

function LoginForm({onSuccess}){
	const [username, setUsername] = useState('');
	const [pwd,           setPwd] = useState('');
	const { t, i18n } = useTranslation();
  
	const onSubmit = e => {
		e.preventDefault();
		// TRY TO LOGIN
		if(username.length > 3 && pwd.length > 3){
			axios.post(`${API_LOCATION}/login/`,{username,password:pwd}).then(({data}) => {
				onSuccess(data); //data is the same as oauthLogin
			}).catch(err=>{ alert(t("login.email.error")) })
		}else{
			alert(t("login.email.error"))
		}
	}

	return <form onSubmit={onSubmit} className='login_form'>
		<input required placeholder="Email or username" type='username' onChange={e=>setUsername(e.target.value)} value={username}/>
		<input required placeholder="Password" type='password' onChange={e=>setPwd(e.target.value)} value={pwd}/>
		<button type="submit">{t('login.submit.login')}</button>
	</form>
}

class Login extends React.Component {
  componentDidMount(){
  	window.scrollBy(0, 10); //to show the navbar
  }

  constructor () {
    super();
    this.state = { 
    	step : LOGIN_BUTTONS,
    	user : {}
    };

    this.oauthLogin  = this.oauthLogin.bind(this);
    this.updateSteps = this.updateSteps.bind(this);
  }

  oauthLogin(provider, access_token){
  	axios.post( `${API_LOCATION}/oauth/login/`, { provider, access_token } ).then( ({data}) => {
		this.setState({ user: data }, this.updateSteps);		
	}).catch( err => {
		console.error(err);
	})
  }

  // EVITAR QUE EL USUARIO ENTRE SI NO HA SUBIDO ESTOS CAMPOS
  updateSteps(){
  	const { signinUser, t } = this.props; 
  	const { user  } = this.state,
  		  { extra } = user;

	if(extra.age === null || extra.country === null){
		this.setState({step:FILL_INFO_FORM});
	}else{
		alert(t('login.welcome'))
		signinUser(user);
	}
  }
  
  render () {
    const { step, user } = this.state; //User is local/temporal
    const { auth, t } = this.props;       //Auth is global

    // Ya está logeado
    if (!_.isEmpty(auth)) return <Redirect to='/map'/>

    switch(step){
    	case FILL_INFO_FORM:
    		return <div className='login-content'>
    			<div className='back_icon' ><Icon onClick={e=>this.setState({step:LOGIN_BUTTONS})} icon={arrowLeftC}/></div>
    			<UserForm mandatory={true} onSuccess={ data=>{ this.setState({ user:{...user,...data} }, this.updateSteps) } } user={user}/>
    		</div>
    	case LOST_PWD_FORM:
    		return <div className='login-content'>
    			<div className='back_icon' ><Icon onClick={e=>this.setState({step:LOGIN_BUTTONS})} icon={arrowLeftC}/></div>
    			<h2>{t('login.email.recover.title')}</h2>
    			<ForgotPWDForm onSuccess={ data=>{ this.setState({step:LOGIN_FORM}) } }/>
    		</div>
    	case REGISTER_FORM:
    		return <div className='login-content'>
    			<div className='back_icon' ><Icon onClick={e=>this.setState({step:LOGIN_BUTTONS})} icon={arrowLeftC}/></div>
    			<h2>Create an account</h2>
    			<RegisterForm onSuccess={ data=>{ this.setState({step:LOGIN_FORM}) } }/>
    		</div>
    	case LOGIN_FORM:
    		return <div className='login-content'>
    			<div className='back_icon' ><Icon onClick={e=>this.setState({step:LOGIN_BUTTONS})} icon={arrowLeftC}/></div>
    			<h2>{t("login.email.title")}</h2>
    			<LoginForm onSuccess={ user => this.setState({ user }, this.updateSteps) }/>
	    		<div className='register_link'><a onClick={e => {e.preventDefault();this.setState({step:LOST_PWD_FORM}) }}>{t("login.email.recover")}</a></div>
    		</div>
    	default: //Show all buttons
	    	return (<div className='login-content'>
	    		<h1>{t("login.title")}</h1>
		        <span>{t("login.subtitle")}</span>

		        <div className='buttons'>  
		          <FacebookLogin
		          	textButton={t("login.button.fb")}
		            appId={facebookAppID}
		            autoLoad={false}
		            fields="name,email,picture"
		            scope="public_profile,email"
		            callback={ ({accessToken}) => this.oauthLogin('facebook',accessToken) } />
		          <div className='button_wrapper'>
		            <div className='email_button' onClick={() => this.setState({ step:LOGIN_FORM })}>
		              <span>{t("login.button.email")}</span>
		            </div>
		          </div>	
		        </div>
		        <div className='register_link'>{t("login.pre_register_email")} <a onClick={e => {e.preventDefault();this.setState({step:REGISTER_FORM}) }}>{t("login.register_email")}</a></div>
	       	</div>);
    }
  }
}

export default connect( ({auth}) => ({ auth }),{signinUser})( withTranslation()(withRouter(Login)) );