// Google key -> public/index.html
export const PROD_API_LOCATION = 'https://api.eteria.co'; //34.235.113.231
export const facebookAppID = '2492676197670126';//'2702745839793270'

export const MAP_STYLE     = 'mapbox://styles/eteria/ck40byru906yl1cp57bxdd9a8'; 
export const MAPBOX_TOKEN  = 'pk.eyJ1IjoiZXRlcmlhIiwiYSI6ImNrNDA3c29taTAwcmszbG8zZGtvdGFkcjUifQ.WrW-qMlPEt3smy3fBc6S4A'//'pk.eyJ1IjoicnViY3VhZHJhIiwiYSI6ImNqZHViMW9xdzFsa2EycXBoeDRtanA1OHYifQ.b8w3Ib2ldqLWM2P13q-cBg'

export const AUTH_LOCAL_KEY = '431gvjkbhw234'
export const TOKEN_DURATION = 86400; //IN SECONDS -> 1 day

// Django Map
export const MAP_POST_KEY   = 'x-csrftoken';
export const MAP_POST_VALUE = 'e84d52ed5e58d0712e374732e480c664';

// tambien revisar styles.scss
export const THEME_COLOR_SECOND = '#0F37DD';

export const COMMON_NAVBAR = [
      {
        label: 'nav.s0',
        staticLink: true,
        path: '/',
        offset: '0',
      },
      {
        label: 'nav.s3',
        path: '/map',
        staticLink: true,
        offset: '0',
      },
      {
        label: 'nav.s4',
        path: '/education',
        staticLink: true,
        offset: '0',
      },
      {
        label: 'nav.s5',
        staticLink: true,
        path: '/blog/posts',
        offset: '0',
      }
];

export const AUTH_ITEMS_COMMON_NAVBAR = [
  {
    label: 'nav.profile',
    staticLink: true,
    path: '/profile',
    offset: '100',
  }
];