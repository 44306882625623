import React, { useContext } from 'react';
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Drawer from 'reusecore/src/elements/Drawer';
import Button from 'reusecore/src/elements/Button';
import Logo from 'reusecore/src/elements/UI/Logo';
import Box from 'reusecore/src/elements/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import { connect } from 'react-redux';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';

import LogoImage from 'common/src/assets/image/portfolio/logo.png';
import LogoImageAlt from 'common/src/assets/image/portfolio/logo-alt.png';
import { COMMON_NAVBAR, AUTH_ITEMS_COMMON_NAVBAR } from '../../../settings';
import _ from 'lodash';
import { useTranslation } from 'react-i18next'

import { signoutUser } from '../../../actions';

const Navbar = ({ auth, navbarStyle, logoStyle, button, row, menuWrapper, menu_items, history, signoutUser }) => {
  var navbar_items = menu_items!==undefined?menu_items:COMMON_NAVBAR;
  const { state, dispatch } = useContext(DrawerContext);
  const isLogged = !_.isEmpty(auth);
  const { t, i18n } = useTranslation()

  if(isLogged){
    navbar_items = [ ...navbar_items, ...AUTH_ITEMS_COMMON_NAVBAR ]
  }

  const onLoginClick = e => {
    e.preventDefault();
    
    if(isLogged){
      signoutUser();
      history.push('/login');
    }
    else{
      history.push('/login');
    }
  }

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="portfolio_navbar">
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          <Link to="/">
            <Logo
              logoSrc={LogoImage}
              title="Portfolio"
              logoStyle={logoStyle}
              className="main-logo"/>
          </Link>
            
          <Link to="/">
            <Logo
              logoSrc={LogoImageAlt}
              title="Portfolio"
              logoStyle={logoStyle}
              className="logo-alt"/>
          </Link>

          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={navbar_items}
              offset={-70}/>

            <a className="navbar_button languages">
                <span onClick={ e=>i18n.changeLanguage('es')} style={{paddingRight:"5px"}}>ES</span>|<span onClick={ e=>i18n.changeLanguage('en')} style={{paddingLeft:"5px"}}>EN</span> 
            </a>

            <a className="navbar_button">
                <Button {...button} onClick={ onLoginClick } title={isLogged?t('navbar.logout'):t('navbar.login')} />
            </a>

            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#3444f1" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={navbar_items}
                drawerClose={true}
                offset={-100}
              />
              
              <a className="navbar_drawer_button">
                <Button {...button} onClick={ onLoginClick } title={isLogged?t('navbar.logout'):t('navbar.login')} />
              </a>

              <a className="navbar_drawer_button languages">
                <span>{t('nav.language')}</span>
                <span onClick={ e=>i18n.changeLanguage('es')} style={{paddingRight:"5px"}}>ES</span>|<span onClick={ e=>i18n.changeLanguage('en')} style={{paddingLeft:"5px"}}>EN</span> 
              </a>              

            </Drawer>

          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '16px',
    pl: '0',
    pr: '0',
    colors: 'primary',
    minHeight: 'auto',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default connect( ({auth}) => ({auth}), { signoutUser } )( withRouter(Navbar) );
