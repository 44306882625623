import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';

import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import _ from 'lodash';
import ProfileForm from '../components/profile_form';

class Profile extends React.Component {
  render () {
    const { user } = this.props;
    if ( _.isEmpty(user) ) return <Redirect to='/login/'/>
  
    return (
      <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Helmet>
          <title>Eteria | Women traveling the world</title>
          <meta name="Description" content="Eteria, creating a safer world for women by generating consciousness." />
        </Helmet>

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>

          <div className="nav-fix"/>

          <div className="profile-content">
            <h2>MY PROFILE</h2>
            <ProfileForm user={user}/>
          </div>



          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>);

  }
}

export default connect( ({auth}) => ({ user:auth }))( withRouter(Profile) );