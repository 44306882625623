import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';

import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';

import './BlogPostDetail.scss'
import './Certification.scss'

import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next'

function PostDetail(props) {
    const post = {
      title:"Education",
      content:'Eteria education',
      description:'Eteria education',
      username:'Eteria',
      published:'02/02/2020',
      picture:{
        url:''
      }
    };

    const description = _.isEmpty(post)?'':post.content.replace(/(<([^>]+)>)/ig,"").slice(0,150);
    const picture     = _.isEmpty(post)?'':post.picture.url;

    const { t } = useTranslation();

    return (
      <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Helmet>
          <title>Eteria | Women traveling the world</title>
          <meta name="Description" content="Eteria, creating a safer world for women by generating consciousness." />
          <meta name="keywords" content="eteria women travel freedom awareness"/>        
          <meta name="author" content={ post.username }/>
          <meta name="organization" content="Eteria"/>
          <meta data-ue-u="description" name="description" content={description}/>
          <meta name="date" content={post.published}/>
          <meta property="article:published_time" content={post.published}/>
          <meta property="article:modified_time" content={post.updated}/>
          <meta name="DC.date.issued" content={post.created}/>
          <meta property="og:title" content={post.title}/>
          <meta property="og:description" content={description}/>
          <meta property="og:image" content={picture}/>
          <meta property="og:url" content={window.location.href}/>
          <meta name="twitter:title" content={post.title}/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:image" content={picture}/>
          <meta name="twitter:card" content={window.location.href}/>
          <meta name="twitter:image:alt" content="Image description"/>
          <meta property="og:type" content="article"/>
          <meta property="og:site_name" content="Eteria"/>
        </Helmet>

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>

          <div className='blog_intro' style={{backgroundImage:`url(${ 1 })`}}>
            <div className='photo_filter'/>
            <h2 className="banner-title">{t('certification.h1')}</h2>
          </div>

          <main className="certification_content">
            <div className="certification__description">
              <p>{t('certification.p1')}</p>
              <p>{t('certification.p2')}</p>
              <p>{t('certification.p3')}</p>
            </div>
            
            <div className="certification__list certification__list--first">
              <h3>{t('certification.list1.title')}</h3>
              <ul>
                <li>{t('certification.list1.li1')}</li>
                <li>{t('certification.list1.li2')}</li>
                <li>{t('certification.list1.li3')}</li>
              </ul>
            </div>

            <div className="certification__list certification__list--second">
              <h3>{t('certification.list2.title')}</h3>
              <ol>
                <li>{t('certification.list2.li1')}</li>
                <li>{t('certification.list2.li2')}</li>
                <li>{t('certification.list2.li3')}</li>
                <li>{t('certification.list2.li4')}</li>
                <li>{t('certification.list2.li5')}</li>
              </ol>
            </div>

            <div className="certification__list certification__list--third">
              <h3>{t('certification.list3.title')}</h3>
              <ol>
                <li><b>{t('certification.list3.li1.title')}</b><span>{t('certification.list3.li1.content')}</span></li>
                <li><b>{t('certification.list3.li2.title')}</b><span>{t('certification.list3.li2.content')}</span></li>
                <li><b>{t('certification.list3.li3.title')}</b><span>{t('certification.list3.li3.content')}</span></li>
              </ol>
            </div>

            <p className="validity">{t("certification.disclaimer")}</p>

            <p className="cta"> {t('certification.cta.text')} <a href="#">{t('certification.cta.anchor')}</a> </p>
          </main>

          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>);
}

export default withRouter(PostDetail);