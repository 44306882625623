import React, { useState } from 'react';
import { withTranslation } from 'react-i18next'
import Rating from 'react-rating';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import { thumbsdown } from 'react-icons-kit/ionicons/thumbsdown';
import { thumbsup } from 'react-icons-kit/ionicons/thumbsup';

import { alert } from 'react-icons-kit/ionicons/alert';
import Icon from 'react-icons-kit';

class UploadReviewClass extends React.Component {
  constructor(props){
    super(props);

    this.state = { 
      rating :undefined,
      comment:"",
      valid:false,
      
      review: undefined,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  validateInputs(){
    const { rating, comment} = this.state;
    // console.log( rating, comment);

    if(rating !== undefined && comment.length !== 0)
      this.setState({valid:true});
  }

  onSubmit(e){
    const { comment, rating } = this.state;
    const { place } = this.props;
    
    axios.post('comments/',{content:comment, score:rating*2, place:place.id}).then( ({data}) => {
      
      this.setState({review:data});

    }).catch(e=>console.error(e))
  }

  updateState({target:{value,name}}){
    this.setState({[name]:value}, this.validateInputs);
  }

  componentDidUpdate(prevProps){ 
    if ( prevProps.place.id !== this.props.place.id ) {
      this.setState({ rating:undefined, comment:"", valid:false })
      this.fetchReview(); 
    }
  }
  componentDidMount(){ this.fetchReview(); }

  fetchReview(){
    const { place:{id}, user } = this.props;
    
    axios.get('/comments/',{ params:{ place:id, user:user.id } }).then( ({data:{next, results, count}}) => {
      this.setState({ review: results.length>0?results[0]:undefined });      
    }).catch( err=>{
      console.error(err);
    });
  }

  render(){
    const { rating, comment, review, valid } = this.state;
    
    if(review !== undefined) return <CommentEntry comment={review}/>

    const {t} = this.props;
    return <div className='upload_review'>
      <Rating 
        onChange={ rate => { this.setState({rating:rate}, this.validateInputs);} }
        initialRating={ rating }
        fractions={2}/>

      <textarea name="comment" value={comment} onChange={ this.updateState } placeholder={t('components.place_detail.comment_placeholder')} cols="30" rows="5"/>
      {valid&&<button onClick={ this.onSubmit } className="upload_review_button">Upload</button>}
    </div>
  }
}

const UploadReview = withTranslation()(UploadReviewClass);

// IF we have the auth user then we can interact, else we just show the comment
function CommentEntry({comment, user}){
  const LIKE_TYPE    = 1,
        DISLIKE_TYPE = 0;

  // likes, dislikes
  const [interacted, setInteracted] = useState(comment.interacted);
  
  const { extra }      = comment.user;
  const interactable   = !_.isEmpty(user);

  const onAlertClick = e => {
    const { confirm, alert } = window;
    
    if( confirm('Are you sure you want to report this comment?') ){
      axios.post('comment_reports/',{user:user.id, comment:comment.id}).then(({data}) =>{
        alert('The comment was reported, our team will take actions on this');
      }).catch(({response:{data}}) => {
        alert(data.data);
      });
    }
  }

  const onDislikeClick = e => {
    axios.post('comment_interactions/',{ type:DISLIKE_TYPE, user:user.id, comment:comment.id }).then( ({data}) => {
      setInteracted(data.type);
    }).catch(error=>{
      console.error(error);
    });
  }

  const onLikeClick = e => {
    axios.post('comment_interactions/',{ type:LIKE_TYPE, user:user.id, comment:comment.id }).then( ({data}) => {
      setInteracted(data.type);
    }).catch(error=>{
      console.error(error);
    });
  }

  return <div className="commentEntry">
    
    <div className="commentHeader"> 
      <div className="avatar" style={ extra?{backgroundImage:`url(${extra.photo})`}:{} } />
      <div className='info'>
        <h3>{comment.user.username}</h3>
        <div className='rating'>
          <Rating readonly initialRating={ comment.score/2 } fractions={2}/>
        </div>
      </div>
    </div>
    
    <div className="commentContent">
      <p> {comment.content} </p>
    </div>

    { interactable && <div className='interactions'>
      <div className='left'>
        <Icon className={`red_hover`} onClick={onAlertClick} icon={alert}/>
      </div>

      <div className='right'>
        <Icon className={`red_hover  ${interacted === DISLIKE_TYPE?'red_active':''}`} onClick={onDislikeClick} icon={thumbsdown}/>
        <Icon className={`blue_hover ${interacted === LIKE_TYPE?'blue_active':''}`} onClick={onLikeClick} icon={thumbsup}/>
      </div>
    </div> }

    <hr/>

  </div>
}

class PlaceComments extends React.Component{
  
  constructor(props){
    super(props);
    this.state = {
      comments:[],
      next:  null,
      count: null,
    };

    this.loadNext = this.loadNext.bind(this);
  }

  componentDidMount(){ this.fetchComments() }

  fetchComments(){
    const { place:{id}, user } = this.props;

    axios.get('comments/',{ params:{ place:id } }).then( ({data:{next, results, count}}) => {
      
      // Remove current user comment if exists
      if( user !== undefined ){
        results = _.filter(results, n=>n.user.id !== user.id );
      }

      this.setState({ next, comments:results, count });

    }).catch( err=>{
      console.error(err);
    });
  }

  componentDidUpdate(prevProps){
    if ( prevProps.place.id !== this.props.place.id ) this.fetchComments();
  }

  loadNext(e){
    const { next, comments } = this.state;
    axios.get(next).then( ({data:{next, results, count}}) => {
      this.setState({ next, comments:[ ...comments, ...results], count });
    }).catch(err => {
      console.error(err)
    });
  }

  render(){
    const { place, user, t } = this.props;
    const { comments, count, next } = this.state;

    return <div className='place_detail_container card'>
      <div className="place_comments_header"><h2>{t('components.place_detail.reviews_header')}</h2>    <h2>{count}</h2></div>
      
      <div className="add_comment">
        { user.id !== undefined?<UploadReview user={user} place={place}/>:<div className='login_cta'><Link to="/login">{t('components.place_detail.login_message')}</Link></div>}
      </div>

      <div className="comments">
        { comments.map(c => <CommentEntry key={c.id} comment={c} user={user}/> ) }
      </div>
      
      
      { next!==null?<div onClick={this.loadNext} className='load_more'>Load more</div>:null }

    </div>
  }

}

export default withTranslation()(PlaceComments);