import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// ========================================
import axios from "axios"
//REDUX
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import reducers from "./reducers"
import { signoutUser } from "./actions"
import thunk from "redux-thunk"
import ReactGA from 'react-ga';
import { AUTH_LOCAL_KEY, TOKEN_DURATION } from './settings'
import { AUTH_USER } from './actions/types';
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
import './styles.scss';

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactGA.initialize('UA-154414932-1');
ReactGA.pageview(window.location.pathname+window.location.search); //Primer hit

const middleware = [thunk, ]
const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore)
export const store = createStoreWithMiddleware(reducers)

//Auth on refresh
const auth = JSON.parse(localStorage.getItem(AUTH_LOCAL_KEY));

if (auth) {
   // Checar que es un token valido
   const current = moment(), 
         created = moment(auth.created);
   
   if ( created.add(TOKEN_DURATION,'seconds').isBefore( current ) ) { 
      //Token Expired
      store.dispatch( signoutUser() );
   } else {
      axios.defaults.headers.common["Authorization"] = `Token ${auth.token}`
      store.dispatch({ type: AUTH_USER, payload: auth })
   }
}

ReactDOM.render(
   <I18nextProvider i18n={i18n}>
      <Provider store={store}>
         <App/>
      </Provider>
   </I18nextProvider>,
   document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
