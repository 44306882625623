import {
	UPDATE_MAP_PLACE,
	CLEAN_MAP_PLACE
} from '../actions/types';

export default function(state={ new_place:{} },action){
	switch(action.type){
		case UPDATE_MAP_PLACE: //Fue exitosa la Auth
			return { new_place:{...action.payload} };
		case CLEAN_MAP_PLACE:
			return { new_place:{} };
		default:
			return state;
	}
}