import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';

import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';
import { StickyShareButtons } from 'sharethis-reactjs';
import './BlogPostDetail.scss'

import { withRouter } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

class PostDetail extends React.Component {
  constructor () {
    super();
    this.state = {
      post: {},
    };
  }

  componentDidMount(){
  	const { id } = this.props.match.params;
  	
    axios.get(`blog/post/`,{ params:{slug:id} }).then( ({data:{count,results}}) => {
      if (count>0) this.setState({post:results[0]});
    }).catch(err=>{
      console.error(err);
    });

    window.scrollTo(0,0);
  }
  
  render () {
    const { post } = this.state;

    const description = _.isEmpty(post)?'':post.content.replace(/(<([^>]+)>)/ig,"").slice(0,90);
    let picture     = _.isEmpty(post)?'':post.picture;
    const toCut = picture.indexOf('?')
    if(toCut >= 0){
      picture = picture.slice(0,toCut);
    }

    return (
      <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Helmet>
          <title>Eteria | Women traveling the world</title>
          <meta name="Description" content="Eteria, website for women who are traveling and looking for recommendations" />
          <meta name="keywords" content={post.keywords}/>        
          <meta name="author" content={post.username}/>
          <meta name="organization" content="Eteria"/>
          <meta data-ue-u="description" name="description" content={description}/>
          <meta name="date" content={post.published}/>
          <meta name="article:published_time" content={post.published}/>
          <meta name="article:modified_time" content={post.updated}/>
          <meta name="DC.date.issued" content={post.created}/>
          <meta name="twitter:title" content={post.title}/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:image" content={picture}/>
          <meta name="twitter:card" content={window.location.href}/>
          <meta name="twitter:image:alt" content="Image description"/>
          <meta property="og:title" content={post.title}/>
          <meta property="og:description" content={description}/>
          <meta property="og:image" content={picture}/>
          <meta property="og:image:secure" content={picture}/>
          <meta property="og:url" content={window.location.href}/>
          <meta property="og:type" content="article"/>
          <meta property="og:site_name" content="Eteria"/>
        </Helmet>

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>

          {!_.isEmpty(post) && <StickyShareButtons
                      config={{
                        alignment: 'left',    // alignment of buttons (left, right)
                        color: 'social',      // set the color of buttons (social, white)
                        enabled: true,        // show/hide buttons (true, false)
                        font_size: 16,        // font size for the buttons
                        hide_desktop: false,  // hide buttons on desktop (true, false)
                        labels: 'cta',     // button labels (cta, counts, null)
                        language: 'en',       // which language to use (see LANGUAGES)
                        min_count: 0,         // hide react counts less than min_count (INTEGER)
                        networks: [           // which networks to include (see SHARING NETWORKS)
                          'linkedin',
                          'facebook',
                          'wechat',
                          'twitter',
                          'reddit',
                          'messenger',
                          'whatsapp',
                          'pinterest',
                          'telegram',
                          'email',
                        ],
                        padding: 12,          // padding within buttons (INTEGER)
                        radius: 10,            // the corner radius on each button (INTEGER)
                        show_total: true,     // show/hide the total share count (true, false)
                        show_mobile: true,    // show/hide the buttons on mobile (true, false)
                        show_toggle: true,    // show/hide the toggle buttons (true, false)
                        size: 48,             // the size of each button (INTEGER)
                        top: 160,             // offset in pixels from the top of the page
                        show_total: false,
                      
                        profiles: {           // social profile links for buttons
                          facebook: 'EteriaWomenTravel',
                          instagram: 'eteriawomen',
                          twitter: 'eteriawomen',
                        },
          
                        // OPTIONAL PARAMETERS
                        description: description,       // (defaults to og:description or twitter:description)
                        title: post.title,            // (defaults to og:title or twitter:title)
                        subject: post.title,  // (only for email sharing)
                        message: `Hey, check out this great post ${window.location.href}`,     // (only for email sharing)
                        username: 'eteriawomen' // (only for twitter sharing)
                      }}/>}

          <div className='blog_intro' style={{backgroundImage:`url(${post.picture})`}}>
            <div className='photo_filter'/>
            <h2>{post.title}</h2>
          </div>

          <div className='blog_content_detail' dangerouslySetInnerHTML={{ __html: post.content }}/>

          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>);

  }
}

export default withRouter(PostDetail);