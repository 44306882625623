import React from 'react';
import { Link } from 'react-router-dom'
import Container from 'common/src/components/UI/Container';
import Heading from 'reusecore/src/elements/Heading';
import SectionWrapper, {
  SectionHeader,
  TitleArea,
  LinkArea,
  Text,
  PostArea,
} from './blogSection.style';

import BlogPost from '../post_card';
import { withTranslation } from 'react-i18next';

import axios from 'axios';
import './styles.scss'

class BlogSection extends React.Component {
  constructor (props) {
    super(props);
    
    const { limit } = props;
    const { i18n:{ language } } = this.props

    this.state = {
      posts : [],
      limit : limit?limit:3,
      offset: 0,
      language
    };
  }
  
  componentDidMount(){
    this.requestPosts();
  }

  onUpdatePosts(url){
    var searchParams = new URLSearchParams(url);
    const offset = searchParams.get("offset");
    this.setState({ offset }, this.requestPosts);
  }

  static getDerivedStateFromProps(props, state) {
    // Change component language
    if (props.i18n.language !== state.language) return { language:props.i18n.language };
    return null;
  }

  componentDidUpdate(prevProps, prevState){
    if (prevState.language !== this.state.language) {
      this.requestPosts();  
    }
  }

  requestPosts(){
    const { limit, offset } = this.state;
    const { i18n:{ language } } = this.props

    axios.get('blog/post/', { params: { limit, offset, language } }).then( ({data:{ results, next, previous, count}}) => {
      this.setState({ posts: results, next, previous, count });
    }).catch(err=>{
      console.error(err);
    });
  }

  render () {
    const { posts, previous, next, limit, offset, count } = this.state;
    const { showPaginator, showHeader } = this.props;

    return (
      <SectionWrapper id="blog_section">
        <Container width="1260px">
          { showHeader && <SectionHeader>
            <TitleArea>
              <Heading content="Blog & News Updates" />
            </TitleArea>
            <LinkArea>
              
              <Link to='blog/posts' className="text__btn">
                <span className="text">View all blog posts</span>
                <span className="arrow" />
              </Link>
            
            </LinkArea>
          </SectionHeader> }

          <div className="post_area">
            {posts.map(item => <BlogPost key={item.id} post={item}/> )}
          </div>

          { showPaginator && <div className='pagination'>
            <div className='buttons'>
              { previous && <a href="#" onClick={e=>{e.preventDefault();this.onUpdatePosts(previous)}}>Previous</a> }
              { next && <a href="#" onClick={e=>{e.preventDefault();this.onUpdatePosts(next)}}>Next</a> }
            </div>
            <span>Showing {posts.length} posts of {count}</span>
          </div> } 

        </Container>
      </SectionWrapper>);
  }
}

export default withTranslation()(BlogSection);
