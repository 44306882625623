import React, { Fragment, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';

import Header from '../components/header'
import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';

import './BlogPostDetail.scss'
import './Education.scss'

import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next'

function EducationView(props) {
    const post = {
      title:"Education",
      content:'Education',
      description:'',
      username:'Eteria',
      published:'02/02/2020',
      picture:{
        url:''
      }
    };

    const description = _.isEmpty(post)?'':post.content.replace(/(<([^>]+)>)/ig,"").slice(0,150);
    const picture     = _.isEmpty(post)?'':post.picture.url;

    const { t } = useTranslation();

    useEffect( ()=>window.scrollTo(0,0) );

    return (
      <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Helmet>
          <title>Eteria | Women traveling the world</title>
          <meta name="Description" content="Eteria, creating a safer world for women by generating consciousness." />
          <meta name="keywords" content="eteria women travel freedom awareness"/>        
          <meta name="author" content={ post.username }/>
          <meta name="organization" content="Eteria"/>
          <meta data-ue-u="description" name="description" content={description}/>
          <meta name="date" content={post.published}/>
          <meta property="article:published_time" content={post.published}/>
          <meta property="article:modified_time" content={post.updated}/>
          <meta name="DC.date.issued" content={post.created}/>
          <meta property="og:title" content={post.title}/>
          <meta property="og:description" content={description}/>
          <meta property="og:image" content={picture}/>
          <meta property="og:url" content={window.location.href}/>
          <meta name="twitter:title" content={post.title}/>
          <meta name="twitter:description" content={description}/>
          <meta name="twitter:image" content={picture}/>
          <meta name="twitter:card" content={window.location.href}/>
          <meta name="twitter:image:alt" content="Image description"/>
          <meta property="og:type" content="article"/>
          <meta property="og:site_name" content="Eteria"/>
        </Helmet>

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>

          <Header 
            title="Vision Program"
            backgroundImage="url('/assets/img/evp_banner3.png')"/>

          <main className="education_content">
            <div className="education__description">
              <h1 style={{textAlign:"center", marginBottom:'50px'}}>{t('education.h1')}</h1>
              <p>{t('education.p1')}</p>
              <p>{t('education.p2')}</p>
              <p>{t('education.p3')}</p>

              <div className="education__list">
              <h3>{t('education.list2.title')}</h3>
              <ul>
                <li>{t('education.list2.li1')}</li>
                <li>{t('education.list2.li2')}</li>
                <li>{t('education.list2.li3')}</li>
                <li>{t('education.list2.li4')}</li>
                <li>{t('education.list2.li5')}</li>
              </ul>
            </div>

              <p>{t('education.p4')}</p>
            </div>
            
            <div className="education__list">
              <h3>{t('education.list.title')}</h3>
              <ul>
                <li>{t('education.list.li1')}</li>
                <li>{t('education.list.li2')}</li>
                <li>{t('education.list.li3')}</li>
                <li>{t('education.list.li4')}</li>
              </ul>
            </div>

            <p className="cta"> {t('education.cta.text')} <a href="mailto:regina@eteria.co?Subject=Eteria%20Vision%20Program%20Information" target="_top">{t('education.cta.anchor')}</a> </p>
          </main>

          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>);
}

export default withRouter(EducationView);