import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Landing  from './pages/Landing';
import Blog from './pages/Blog';
import BlogPostDetail from './pages/BlogPostDetail';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Education from './pages/Education';
import Certification from './pages/Certification';
import MapView from './pages/Map'

import { createBrowserHistory as createHistory } from 'history'
import ReactGA from 'react-ga'

const history = createHistory()
history.listen(location => {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname+window.location.search); 
})

function App() {
  return <Router>
      <div>
        <Switch>
          <Route path="/blog/posts/:id"> <BlogPostDetail/> </Route>
          <Route path="/blog/posts/"> <Blog/> </Route>
          <Route path="/login"> <Login/> </Route>
          <Route path="/profile"> <Profile/> </Route>
          <Route path="/education"> <Education/> </Route>
          <Route path="/certification"> <Certification/> </Route>
          <Route path="/map"> <MapView/> </Route>
          <Route path="/"> <Landing/> </Route>
          
        </Switch>
      </div>
  </Router>
}

export default App;
