import React, { useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import _ from 'lodash';
import axios from 'axios';
import { API_LOCATION, signinUser } from '../../actions'
import './styles.scss'
import { useDropzone } from 'react-dropzone';
import { orientate, compress} from '../../utils';
import { connect } from 'react-redux'

const countries     = countryList().getData();
const age_intervals = [
	{label:'16-21',value:18},
	{label:'22-25',value:23},
	{label:'26-32',value:29},
	{label:'33-38',value:35},
	{label:'39-45',value:42},
	{label:'46-52',value:49},
	{label:'53-60',value:56},
	{label:'60-70',value:65},
	{label:'+70'  ,value:70},
]; 

// Mandatory nos dice donde estmos, si en vista Login o si es suado por vista Profile
function UserForm({user, onSuccess, mandatory=false, signinUser}){	
	const { extra } = user;
	
	// Form
	const [username, setUsername] = useState(user.username);
	const [job,     setJob]       = useState(extra.job==null?'':extra.job);
	const [selectedCountry, setSCountry]   = useState( extra.country===undefined?undefined:_.find(countries, { value:extra.country }) );
	const [selectedAge,     setSAge]       = useState( extra.age    ===undefined?undefined:_.find(age_intervals, { value:extra.age }) );
	
	// Avatar
	const [file, setFile] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const onSubmit = e => {
		e.preventDefault();

		const country = selectedCountry?selectedCountry.value:null;
		const age     = selectedAge?selectedAge.value:null;

		//Usado en Login cuando faltan datos
		if (mandatory){
			if ( country !== null && age !==null && username.length > 0) {
				// UPDATE al perfil SI todos los campos son validos
				axios.post(`${API_LOCATION}/profile/`,{
					username,
					country,
					age,
					job
				},{ headers:{Authorization:`Token ${user.token}`} }).then(({data}) => {
					onSuccess(data);
				}).catch(({response:{data}}) => { alert(data.error) })
			}else{
				window.alert('Please fill in the required* fields')
			}
		}else{ //Vista del perfil
			axios.post(`${API_LOCATION}/profile/`,{
				username,
				country,
				age,
				job
			}).then(({data}) => {
				signinUser({...user,...data});
			}).catch( ({response}) => {
				console.log( response );
			} );
		}
	}

	const onDrop = acceptedFiles=>{
	    const cfile = acceptedFiles[0];
		if(cfile === undefined) return;

	    // Oriantate&Compress picture
	    orientate(cfile, orientated_file=>{
	        compress(orientated_file, compressed_file=>{         
	            compressed_file.url = URL.createObjectURL(compressed_file);
	            setFile(compressed_file); //Save for later
	        });
	    });
	}

	const updateAvatar = () => {
		var fd = new FormData();
	    fd.append('photo',file);
	    axios.post(`${API_LOCATION}/profile/`,fd).then( ({data}) => {
		  	signinUser( {...user, ...data} );
		  	setFile(undefined);
		  	setLoading(false);
	    }).catch( ({response}) => {  
	    	setFile(undefined);
	    	window.alert('There was an error, try again later.')
	    	setLoading(false);
	    });
	    setLoading(true);
	}

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple:false, accept: 'image/jpeg, image/png' });

	return <form onSubmit={onSubmit} className='login_form'>
		
		{ 
			mandatory?
			<div/>:
			<div className='avatar_form'>
				<label>Avatar</label>
	
				<div className="avatar_container flex-center">
			      <div {...getRootProps({className: "avatar_dropzone"})}  style={ file!==undefined?{ backgroundImage:`url("${file.url}")`}:(extra.photo?{backgroundImage:`url("${extra.photo}")`}:{}) } >
			        <input {...getInputProps()} />
			      </div>
			    </div>
	
			    <div className={`confirm_buttons ${(file===undefined||loading)?'hidden':''}`}>
					<button onClick={ e=>{e.preventDefault();setFile(undefined)} } style={{backgroundColor:'#FFF'}}>Cancel</button>
					<button onClick={ e=>{e.preventDefault();updateAvatar()} }>Confirm</button>    
				</div>
			</div>
		}

		<div><label>Username{mandatory?'*':''}</label><input disabled={false} required={mandatory} placeholder="Username" type="username" onChange={e=>setUsername(e.target.value.slice(0,40))} value={username}/></div>
		
		<div><label>Country of origin{mandatory?'*':''}</label><Select required className='select_country' options={countries} value={selectedCountry} onChange={val => setSCountry(val)}/></div>
		<div><label>Age{mandatory?'*':''}</label><Select required className='select_country' placeholder=' ' options={age_intervals} value={selectedAge} onChange={val => setSAge(val)}/></div>

		<div><label>Occupation</label><input type="text" onChange={e=>setJob(e.target.value)} value={job}/></div>
	
		<button type="submit">Submit</button>
	</form>
}

export default connect(state=>{return {}},{signinUser})(UserForm)