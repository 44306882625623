import React, { useState } from 'react';
import { connect } from 'react-redux';
import Rating from 'react-rating';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import './styles.scss'
import _ from 'lodash';
import { arrowUpB } from 'react-icons-kit/ionicons/arrowUpB';
import { arrowDownB } from 'react-icons-kit/ionicons/arrowDownB';
import Icon from 'react-icons-kit';

import PlaceComments from './place_comments';

function formatType(place_type){
  return place_type.replace(/_or_/g, '/').replace(/_/g, ' ')
}

function PlaceMainInfoCard({place}){
  return <div className='place_detail_container card'>
      <div className="photo_container" style={{backgroundImage: `url(${place.photo})`}}/>
      <div className="place_info">
        <h2>{place.name}</h2>
        
        <div className='rating'>
          <Rating 
            readonly
            initialRating={ place.rating/2 }
            fractions={2}/>

          {place.rating?<span className='numeric'>{place.rating/2}</span>:null}
        </div>

        <div className='place_types'>
          { 
            place.types.map( (p,ix) =>
              <div key={ix}>{formatType(p)}</div>
            ) 
          }
        </div>

        {
          place.certified?<div className='certified'>
            Certified  
          </div>:null
        }

      </div>
    </div>
}

class PlaceDetail extends React.Component {
  constructor (props) {
    super(props);
    
    const { place } = props;
    this.state = {
      place,
      hidden: false
    };
  }

  componentDidUpdate(prevProps){
    if ( prevProps.place === undefined || prevProps.place.id !== this.props.place.id ) this.setState({hidden:false});
  }
  
  render () {
    const { hidden }        = this.state;
    const { auth, place  }  = this.props;
    if (place === undefined || _.isEmpty(place) ) return <div/>
    
    // Certified, comments, position, rating, types, photo, address -> Array de {short_name,long_name,types}
    return <div className={`place_detail_wrapper ${hidden?'hidden':'visible'}`}> 
      <div onClick={e=>this.setState({hidden:!hidden})} className='toggle_visibility'><Icon icon={hidden?arrowUpB:arrowDownB} size={30}/> </div>
      
      <PlaceMainInfoCard place={place}/>
      <PlaceComments place={place} user={auth}/>
    </div>

  }
}

function mapStateToProps({auth}){
	return { auth }
}

export default withRouter( connect( mapStateToProps, {} )(PlaceDetail) );
