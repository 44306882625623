import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';

import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,
  PortfolioLink,
  BuiltWith,
  PortfolioMeta,
  MetaItem,
} from './portfolioShowcase.style';
import { PrevButton, NextButton } from '../portfolio.style';

import PortfolioImage1 from 'common/src/assets/image/portfolio/portfolio-1.jpg';
import EducationImage from 'common/src/assets/image/eteria/showcase/visionProgram.png';
import CertificateImage from 'common/src/assets/image/eteria/showcase/certificate.png';
import { useTranslation } from 'react-i18next'

const PortfolioShowcase = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
}) => {
  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
    animationDuration: 900,
  };

  const { t } = useTranslation();

  // {
  //     title: 'ANIMATION',
  //     portfolioItem: [
  //       {
  //         title: 'Canada Media Site',
  //         description:
  //           "An effective and immersive user experience is what catches the attention and spreads a clear message. That's why we attach great importance to the fact that ergonomics serves the design, and that this design is innovative and neat.",
  //         image: PortfolioImage1,
  //         link: '#',
  //         featuredIn: 'AWWWARDS',
  //         featuredLink: '#',
  //         view: '4.5K',
  //         love: '1.5K',
  //         feedback: '1.2K',
  //         buildWith: [
  //           {
  //             content: 'React JS',
  //           },
  //           {
  //             content: 'Next JS',
  //           },
  //           {
  //             content: 'Styled Component',
  //           },
  //         ],
  //       },
  //       {
  //         title: 'RedQ, Inc. mobile app',
  //         description:
  //           "An effective and immersive user experience is what catches the attention and spreads a clear message. That's why we attach great importance to the fact that ergonomics serves the design, and that this design is innovative and neat.",
  //         image: PortfolioImage1,
  //         link: '#',
  //         featuredIn: 'AppStore',
  //         featuredLink: '#',
  //         view: '8.5K',
  //         love: '5.5K',
  //         feedback: '3.2K',
  //         buildWith: [
  //           {
  //             content: 'React Native',
  //           },
  //           {
  //             content: 'Firebase',
  //           },
  //           {
  //             content: 'Styled Component',
  //           },
  //         ],
  //       },
  //     ],
  //   },

  const SHOWCASE = [
    // {
    //   title: t('showcase.tab1'),
    //   portfolioItem: [
    //     {
    //       title: t("showcase.tab1.title"),
    //       description: t("showcase.tab1.content"),
    //       image: PortfolioImage1,
    //       link: '#',
    //       featuredText: t('showcase.tab1.featuredText'),
    //       featuredIn: t('showcase.tab1.featuredIn'),
    //       featuredLink: '/certification',
    //       // view: '8.5K',
    //       // love: '5.5K',
    //       // feedback: '3.2K',
    //       // buildWith: [
    //       //   {
    //       //     content: 'React Native',
    //       //   },
    //       //   {
    //       //     content: 'Firebase',
    //       //   },
    //       //   {
    //       //     content: 'Styled Component',
    //       //   },
    //       // ],
    //     },
    //   ],
    // },
    {
      title: t('showcase.tab2'),
      portfolioItem: [
        {
          title: t("showcase.tab2.title"),
          description: t("showcase.tab2.content"),
          image: EducationImage,
          link: '#',
          featuredText: t('showcase.tab2.featuredText'),
          featuredIn: t('showcase.tab2.featuredIn'),
          featuredLink: '/education',
        },
        {
          title: t("showcase.tab3.title"),
          description: t("showcase.tab3.content"),
          image: CertificateImage,
          link: '#',
        },
      ],
    },
    // {
    //   title: t('showcase.tab3'),
    //   portfolioItem: [
    //     {
    //       title: t("showcase.tab3.title"),
    //       description: t("showcase.tab3.content"),
    //       image: PortfolioImage1,
    //       link: '#',
    //     },
    //   ],
    // },
  ];

  return (
    <Box {...sectionWrapper} as="section" id="portfolio_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content={ t('showcase.title') } />
          <Text
            {...secDescription}
            content={ t('showcase.description') }/>
        </Box>

        <PortfolioShowcaseWrapper>
          <Tabs
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent animated={false} />}
          >
            {SHOWCASE.map((tabItem, index) => (
              <TabPane
                tab={
                  <Text
                    content={tabItem.title}
                    data-text={tabItem.title}
                    as="span"
                  />
                }
                key={index + 1}
              >
                <GlideCarousel
                  carouselSelector={`portfolio-showcase-carousel-${index + 1}`}
                  options={carouselOptions}
                  prevButton={
                    <PrevButton>
                      <span />
                    </PrevButton>
                  }
                  nextButton={
                    <NextButton>
                      <span />
                    </NextButton>
                  }
                >
                  <>
                    {tabItem.portfolioItem.map((portfolioItem, index) => (
                      <GlideSlide key={`PortfolioShowcaseItem-${index}`}>
                        <PortfolioShowcaseItem>
                          <Box {...portfolioImage}>
                            <Link to={portfolioItem.featuredLink || '#'} >
                              <Image
                                src={portfolioItem.image}
                                alt={`PortfolioImage-${index + 1}`}
                              />
                            </Link>
                          </Box>
                          <Box {...portfolioDetails}>
                            
                            {/*
                            <PortfolioLink>
                              <Link href={portfolioItem.link || '#'}>
                                <a>VISIT LIVE SITE</a>
                              </Link>
                            </PortfolioLink>
                            */}

                            <Link to={portfolioItem.featuredLink || '#'} >
                              <Heading
                                className="main-color"
                                content={portfolioItem.title}
                                {...titleStyle}/>
                            </Link>
                            <br/>
                            <br/>
                            <Text
                              className="spaced-text"
                              content={portfolioItem.description}
                              {...detailsStyle}
                            />
                            {portfolioItem.buildWith ? (
                              <BuiltWith>
                                {portfolioItem.buildWith.map((item, index) => (
                                  <span key={`buildWith-item-${index}`}>
                                    {item.content}
                                  </span>
                                ))}
                              </BuiltWith>
                            ) : (
                              ''
                            )}
                          </Box>

                          {portfolioItem.featuredIn ||
                          portfolioItem.view ||
                          portfolioItem.love ||
                          portfolioItem.feedback ? (
                            <PortfolioMeta>
                              {portfolioItem.featuredIn ? (
                                <MetaItem className="meta_featured">
                                  {portfolioItem.featuredText}
                                  <Link to={portfolioItem.featuredLink || '#'} >
                                    {portfolioItem.featuredIn}
                                  </Link>
                                </MetaItem>
                              ) : (
                                ''
                              )}
                              {portfolioItem.view ? (
                                <MetaItem>
                                  <b>{portfolioItem.view}</b> View
                                </MetaItem>
                              ) : (
                                ''
                              )}
                              {portfolioItem.love ? (
                                <MetaItem>
                                  <b>{portfolioItem.love}</b> Love
                                </MetaItem>
                              ) : (
                                ''
                              )}
                              {portfolioItem.feedback ? (
                                <MetaItem>
                                  <b>{portfolioItem.feedback}</b> Feedback
                                </MetaItem>
                              ) : (
                                ''
                              )}
                            </PortfolioMeta>
                          ) : (
                            ''
                          )}
                        </PortfolioShowcaseItem>
                      </GlideSlide>
                    ))}
                  </>
                </GlideCarousel>
              </TabPane>
            ))}
          </Tabs>
        </PortfolioShowcaseWrapper>
      </Container>
    </Box>
  );
};

PortfolioShowcase.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

PortfolioShowcase.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
    pb: ['60px', '80px', '100px', '110px', '150px'],
  },
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: ['50px', '65px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default PortfolioShowcase;
