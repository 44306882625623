import React, { Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';

// import BannerSection from '../containers/Portfolio/Banner';

import Header from '../components/header'

import Navbar from '../containers/Portfolio/Navbar';
import FeaturedPlacesSection from '../containers/Portfolio/Featured';
import Showcase from '../containers/Portfolio/Showcase';
import ProcessSection from '../containers/Portfolio/Process';
import BlocksSection from '../containers/Portfolio/Blocks';
import CallToAction from '../containers/Portfolio/CallToAction';
import TestimonialSection from '../containers/Portfolio/Testimonial';
import PartnersSection from '../containers/Portfolio/Partners';
import ContactSection from '../containers/Portfolio/Contact';
import Footer from '../containers/Portfolio/Footer';
import BlogSection from '../components/top_posts';
import Map from '../components/mapgl';

import { useTranslation } from 'react-i18next'

export default () => {  
  const { t, i18n } = useTranslation();
  const LANDING_NAVBAR = [
    {
      label: 'nav.s1',
      path: '#about',
      offset: '0',
    },
    {
      label: 'nav.s3',
      path: '/map',
      staticLink: true,
      offset: '0',
    },
    {
      label: 'nav.s4',
      path: '/education',
      staticLink: true,
      offset: '0',
    },
    {
      label: 'nav.s5',
      path: '/blog/posts',
      staticLink: true,
      offset: '0',
    }
  ];

  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Helmet>
          <title>Eteria | Women traveling the world</title>
          <meta name="Description" content="Eteria, creating a safer world for women by generating consciousness." />
        </Helmet>

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar menu_items={LANDING_NAVBAR}/>
            </DrawerProvider>
          </Sticky>
          
          <Header spaced/>

          <div id="about">
            
            <div className="text-box"> 
              <h3 className="main-color">{t('about.title')}</h3>
              <p>{t('about.content')}</p>
              <p className="subtitle3">{t('banner.subtitle3')}</p>
            </div>
            
            <div className="img-wrapper">
              { i18n.language === 'en'?<img src="/assets/img/about_en.png"/>:<img src="/assets/img/about_es.png"/> } 
            </div>
            
          </div>

          <div id="map" className='map-container'>
            <h2>{t('map.title')}</h2>  
            <div className='paragraph' dangerouslySetInnerHTML={ {__html: t('map.subtitle', {interpolation: {escapeValue: false}})} } />
            <div className="map-wrapper"><Map/></div>
          </div>

          <div id="discover"><FeaturedPlacesSection/></div>

          <div id="features">
            <Showcase/>
            {/*<ProcessSection />*/}
            <BlocksSection />
          </div>
          
          <div id="blog">
            <CallToAction />
            <div className="blog-bg">
              <BlogSection/>
            </div>
          </div>

          <div id="testimonies">
            <TestimonialSection />
            <PartnersSection />
          </div>
          
          {/*<ContactSection />*/}
          <div id="contact"/>
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
