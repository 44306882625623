import React, { Component } from 'react';
import { connect } from 'react-redux';
import GooglePlacesAutocomplete from '../googleplaces_search';
import mapboxgl from 'mapbox-gl';
import axios from 'axios';
import { MAP_POST_KEY, MAP_POST_VALUE, MAPBOX_TOKEN, MAP_STYLE  } from '../../settings';
import './styles.scss';
import _ from 'lodash';
import { renderToString } from 'react-dom/server'
import Rating from 'react-rating';
import PlaceDetail from '../place_detail'
import { withTranslation } from 'react-i18next'
import { search } from 'react-icons-kit/ionicons/search';
import Icon from 'react-icons-kit';

import { cleanMapPlace } from '../../actions';

// function PopupInfo({place}){
	
// 	return <div className='popupInfo'>
// 		<div className='imageContainer' style={ place.photo?{backgroundImage:`url(${place.photo})`}:{} }> 
// 			<div className={place.certified?'certified':''}/>
// 		</div>
// 		<div className='placeDetail'>
// 			<div className='rating'>
// 				<Rating 
// 					readonly
// 					initialRating={ place.rating/2 }
// 					fractions={2}/>
// 			</div>
// 			<h4><a href={`places/${place.id}`}>{place.name}</a></h4>
// 		</div>
// 	</div>
// }


mapboxgl.accessToken = MAPBOX_TOKEN;
// https://www.mapbox.com/install/js/cdn-install/

// const mapStyles = {
//   standard:'mapbox://styles/rubcuadra/cji1pb6bd5nmq2slamjzxo28i',
//   icecream:"mapbox://styles/rubcuadra/cji1pcyiw5ic12spdazry5i53",
//   terminal:"mapbox://styles/rubcuadra/cji1pckjb4nzk2rn44t9fwgk0",
//   whaam:"mapbox://styles/rubcuadra/cji1osq695au12st2lei6f6az",
//   decimal:"mapbox://styles/rubcuadra/cji1pckjb4nzk2rn44t9fwgk0",
//   northstar:"mapbox://styles/rubcuadra/cji1pc8p56d242sns8s73h4km",
//   moonlight:"mapbox://styles/rubcuadra/cji1ourif37d72spbmzwihg2p",
//   vintage:"mapbox://styles/rubcuadra/cji1pc1b66cys2sr09o9ibb6x",
// };

class MapView extends Component{
	// props.place = {
    //   id: place_id,
    //   position: [lng,lat],
    //   description,
    //   types,
    //   address_components
    // }

	constructor(props){
		super(props);  
	    
	    this.map   = undefined;
	    this.state = { 
	    	firstLoad       : true, 
	    	mapLayers       : undefined,
	    	mapStyle        : MAP_STYLE,//'mapbox://styles/rubcuadra/cji1pcyiw5ic12spdazry5i53',
	    	markers         : [],
	    	showSearchButton: false,
	    	place           : {},
	    	nearby_places   : [],
	    };
	    this.defaultCenter = [-99.133209, 19.432608]; //Mexico City
	    this.onSelectedPlace     = this.onSelectedPlace.bind(this);
	    this.onSearchNearbyClick = this.onSearchNearbyClick.bind(this);
	}

    renderPlaces(){
    	const { markers, place, nearby_places } = this.state; 
    	// DELETE PREVIOUS MARKERS THAT ARE NOT PART OF CURRENT nearby_places
    	for (var i = 0; i < markers.length; i++) {
    		var found = _.findIndex( nearby_places, { id: Number(markers[i]._element.id) });
    		if( found === undefined || found < 0)
    			markers[i].remove();
    	}

    	// Show new Markers
    	var new_markers = [];
    	for (var i = 0; i < nearby_places.length; i++) {
    		var iPlace = nearby_places[i];
    		
    		//Ya existe, no es necesario volver a ponerlo
    		if( document.getElementById(`Marker${iPlace.id}`) !== null ) continue; 

    		// CREATE MARKER
    		// var popup = new mapboxgl.Popup({ offset: 15 }).setHTML( renderToString(<PopupInfo place={iPlace}/>) );
			var el = document.createElement('div'); 
			el.id = `marker_${ iPlace.id }`; 
			el.className = `popupMarker ${iPlace.id === place.id?'current':''} ${iPlace.certified?'certified':''}`;
			// el.style.backgroundImage = `url(${iPlace.photo?iPlace.photo:'https://docs.mapbox.com/mapbox-gl-js/assets/washington-monument.jpg'})`;
			el.addEventListener('click', (e) => {
				let place_id = e.target.id.split('_')[1];
				let p = _.find(nearby_places, {id:Number(place_id)})
				this.updatePlace(p, false);
			});

			// Save markers reference
			new_markers.push(  
				new mapboxgl.Marker(el)
				.setLngLat( iPlace.position )
				// .setPopup( popup ) 
				.addTo(this.map)
			);
    	}

		// Update markers
		this.setState({ markers:new_markers })
    }

    // Redux flow
    componentDidUpdate(prevProps, prevState){
    	const new_place = this.props.redux_places.new_place,
    	 prev_new_place = prevProps.redux_places.new_place;
    	
    	// We got a new place, render it and clean everything
    	if( _.isEmpty(prev_new_place) && !_.isEmpty(new_place) ){
			// Poner lugar
			this.updatePlace( new_place );
    		// Limpiar a nivel redux
			this.props.cleanMapPlace();
    	}
    }

    componentWillUnmount(){
    	// DELETE MARKERS
    	const { markers } = this.state;
    	for (var i = 0; i < markers.length; i++) markers[i].remove();
    }
		
	componentDidMount(){
		const { place:{ position } } = this.state;
		
		var center = position!==undefined?position:this.defaultCenter;

		this.map = new mapboxgl.Map({
			container: 'firstMapId',
			style: this.state.mapStyle, //mapbox://{user}/{map_id} || mapbox/' + layerId + '-v9'
			zoom: 12,
			pitch: 0,
			center, //Obtener la location del usuario
			dragRotate: true,
			attributionControl: false,
		    // bearing: -17.6,
		}).on('load', e=>{ 
			const mapLayers = this.map.getStyle().layers;

			var labelLayerId;
			for (var i = 0; i < mapLayers.length; i++) {
				if (mapLayers[i].type === 'symbol' && mapLayers[i].layout['text-field']) {
					labelLayerId = mapLayers[i].id;
					break;
				}
			}
			this.map.addLayer({
				'id': '3d-buildings',
				'source': 'composite',
				'source-layer': 'building',
				'filter': ['==', 'extrude', 'true'],
				'type': 'fill-extrusion',
				'minzoom': 15,
				'paint': {
				'fill-extrusion-color': '#aaa',
				 
				// use an 'interpolate' expression to add a smooth transition effect to the
				// buildings as the user zooms in
				'fill-extrusion-height': [
				"interpolate", ["linear"], ["zoom"],
					15, 0,
					15.05, ["get", "height"]
				],
				'fill-extrusion-base': [
				"interpolate", ["linear"], ["zoom"],
					15, 0,
					15.05, ["get", "min_height"]
				],
				'fill-extrusion-opacity': .6
				}
			}, labelLayerId);

			// this.map.addControl(new mapboxgl.FullscreenControl());
			this.map.addControl(new mapboxgl.NavigationControl());
			// this.map.addControl(new mapboxgl.GeolocateControl({ positionOptions: { enableHighAccuracy: true }, trackUserLocation: true }));

			this.setState({ mapLayers });
			this.onSearchNearbyClick();
		});

		// Add geolocate control to the map.
		this.map.addControl(new mapboxgl.GeolocateControl({
			positionOptions: {
				enableHighAccuracy: true
			},
			trackUserLocation: true
		}));

		this.map.on('dragend', event => {
			this.setState({ showSearchButton: true });
		});
	}

	onSearchNearbyClick(){
		const { lat, lng } = this.map.getCenter();
		this.fetchNearbyPlaces({lat,lng});
	}

	fetchNearbyPlaces({ lat,lng }){
		axios.get('places/',{ params: { lat,lng } }).then( ({ data:{results} }) => {
			this.setState({ nearby_places: [...results] }, this.renderPlaces);
			this.setState({ showSearchButton: false });
		}).catch( err => {
			console.error( err );
			this.setState({ showSearchButton: false });
		})
	}

	updatePlace( place, fetch=true ){
		this.setState({ place });
		this.map.flyTo( {center: [place.position[0], place.position[1] ], zoom: 15});
		if (fetch) this.fetchNearbyPlaces( place.position ); //Este llama al render
	}

	onSelectedPlace(place){
	    const { place_id } = place;
	    // BUSCAR EN NUESTRO SERVER
	    this.setState({loading:true});
	    axios.get('places/',{ params:{ google_id:place_id } }).then( ({data:{ count, results }}) => {
	    	if ( count > 0){ //Existe en el servidor, ya se armó
	    		this.updatePlace(results[0]);
	    		this.setState({loading:false});
	    	}else{ //Buscar en google y guardar en server	    		
	    		// https://developers.google.com/places/web-service/details
			    var temp_div = document.createElement('div');
			    var placesService = new window.google.maps.places.PlacesService( temp_div );
			    const { OK } = window.google.maps.places.PlacesServiceStatus;    
			    placesService.getDetails({ 
			        language: 'en',
			        placeId : place_id,
			        fields  : ['address_component', 'name', 'photo', 'place_id', 'type', 'geometry', 'international_phone_number', 'website']
			    }, ( placeDetails, status) => {
			          if (status === OK) {
			            const { address_components, name, photos, place_id, types, geometry:{ location }, international_phone_number, website } = placeDetails;

			            const place = {
			              google_id: place_id,
			              position: [ location.lng() , location.lat() ],
			              name,
			              types,
			              address: address_components,
			              website,
			              phone_number: international_phone_number,
			              photo: photos?photos[0].getUrl():null
			            }

			            // GUARDAR EN EL SERVER y usar el objeto regresado como place
			            axios.post('places/', place, { headers: { [MAP_POST_KEY]:MAP_POST_VALUE } }).then( ({data}) => {
				            this.updatePlace( data );
				            this.setState({ loading: false }); 
			            }).catch( err => {
			            	console.error( err ); //Error al crear en nuestro server
			            	this.setState({ loading: false }); 
			            })
			          }else{ //ERROR Google, nos devolvio algo diferente a OK
			            console.error( place );
			            this.setState({ loading: false }); 
			          }
			    });
	    	}
	    }).catch( err=>{
	    	console.error(err);
	    });
	}

	render(){
		const { showSearchButton, loading, place } = this.state;
		const { t } = this.props;

		return <div style={{ overflow:'hidden', position: 'relative', width: "100%", height: "100%"}}>
			<div onClick={ this.onSearchNearbyClick } className={`search_nearby_button ${showSearchButton?'':'hidden'}`}> 
				<Icon icon={search} size={18}/>
			</div>
			
			<PlaceDetail place={place}/>
			<GooglePlacesAutocomplete 
				autocompletionRequest = {{ types: ['establishment',] }}
          		placeholder={ t('map.searchbar') } 
          		onSelect={ this.onSelectedPlace }/>

			<div id="firstMapId" style={{width: "100%", height: "100%"}} />

			{loading && <div className='loader'><span className='blink'>Loading</span></div>}
		</div>
	}
}

function mapStateToProps({map}){
	return {
		redux_places:{...map}
	};
}

export default connect(mapStateToProps,{ cleanMapPlace })( withTranslation()(MapView)) ;

