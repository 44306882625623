import React,  { useState } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Input from 'reusecore/src/elements/Input';
import Container from 'common/src/components/UI/Container';
import SocialProfile from '../SocialProfile';

import {
  FooterWrapper,
  Newsletter,
  FooterNav,
  FooterNavItem,
} from './footer.style';
import { FOOTER_MENU } from 'common/src/data/Portfolio/data';
import { heart } from 'react-icons-kit/fa/heart';
import axios from 'axios';
import { useTranslation } from 'react-i18next'

import { socialInstagram } from 'react-icons-kit/ionicons/socialInstagram';
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';

import { mail } from 'react-icons-kit/feather/mail';

import { FlagIcon } from "react-flag-kit";

const Footer = ({
  row,
  col,
  titleStyle,
  linkStyle,
  newsletterButton,
  copyrightStyle,
  contactItem,
  flexBox,
  contactTitle,
  contactInfo,
  noMargin,
}) => {
  
  const { t, i18n } = useTranslation()
  const [email, setEmail] = useState('');

  function addToNewsletter(){
    if( /\S+@\S+\.\S+/.test(email) ){
      axios.post('newsletter/',{email}).then(ans=>{
        window.alert( t('newsletter.confirmation') );
      }).catch(err=>{
        window.alert( t('newsletter.error') );
      })
    }else{
      alert('Invalid email');
    }
  }

  const SOCIAL_PROFILES = [
    {
      icon: socialInstagram,
      url: 'https://www.instagram.com/eteriawomen/',
    },
    {
      icon: socialFacebook,
      url: 'https://www.facebook.com/EteriaWomenTravel/',
    },
    {
      icon: mail,
      url: 'mailto:info@eteria.co',
    },

  ];

  const FOOTER_MENU = [
    {
      language_code : 'es',
      flag: 'MX'
    },
    {
      language_code : 'en',
      flag: 'US'
    },
  ];

  return (
    <FooterWrapper>
      <Container noGutter mobileGutter width="1200px">
        <Box {...row}>
          
          <Box {...col}>
            <Heading
              as="h3"
              content={t('footer.content')}
              {...titleStyle}/>
            
          </Box>

          <Box {...col}>
            <Heading as="h3" content={t('footer.newsletter')} {...titleStyle} />
            <Newsletter>
              <Input
                value={email}
                onChange = { e=>setEmail( `${e}` ) }
                inputType="email"
                placeholder="Your email goes here"
                iconPosition="right"
                isMaterial={false}
                className="email_input"
                aria-label="email"/>

              <Button {...newsletterButton} onClick={ addToNewsletter } title="Subscribe" />
            </Newsletter>
          </Box>
        </Box>

        <Box {...row}>
          <Box {...col}>
            
            <Heading as="h3" content={t('footer.contact')} {...linkStyle} style={{color:'white', paddingBottom: "15px"}} />
            
            <SocialProfile
              className="footer_social"
              items={SOCIAL_PROFILES}
              iconSize={45}/>

            <Text
              as="span"
              content={`© ${(new Date()).getFullYear()} All rights reserved.`}
              {...copyrightStyle}/>
            
            <Link to="#">  
                {' '} <Text as="span" content=" Eteria." {...copyrightStyle} />
            </Link>

            <br/>
            <br/>
            
            <a href="https://www.eteria.co/assets/legal/avisoPrivacidad.pdf" target="_blank" style={{...copyrightStyle}}>
              {t('footer.privacy')}
            </a>
            <br/>
            <a href="https://www.eteria.co/assets/legal/terminosYcondiciones.pdf" target="_blank" style={{...copyrightStyle}}>
              {t('footer.terms')}
            </a>

          </Box>
          
          <Box {...col} {...flexBox}>
            <Box {...contactItem}>
              <Text content={t('footer.language')} {...contactTitle} />
              {/*<Text content="redq.io" {...contactInfo} />*/}
            </Box>
            
            <Box {...contactItem} id="languages">
              <FooterNav>
              {
                FOOTER_MENU.map((item, index) => (
                  <FooterNavItem key={`footer-nav-item-${index}`}>
                    {/*<FlagIcon className="clickeable" code={item.flag} size={28} onClick={ e=>i18n.changeLanguage(item.language_code)} />*/}
                    <span style={{fontSize:'28px', textTransform:'uppercase'}} className="clickeable" code={item.flag} size={28} onClick={ e=>i18n.changeLanguage(item.language_code)}>{item.language_code}</span>
                  </FooterNavItem>
                ))
              }
              </FooterNav>
            </Box>
          </Box>
          
        </Box>

        
        {/*<Box {...row} {...noMargin}>
          <Box {...col}>
            <Text
              as="span"
              content="Built & designed with"
              {...copyrightStyle}/>
            <Icon icon={heart} size={14} className="heart_sign" />
          </Box>

          <Box {...col} {...flexBox}>
            <FooterNav>
              {
                FOOTER_MENU.map((item, index) => (
                  <FooterNavItem key={`footer-nav-item-${index}`}>
                    <FlagIcon code={item.flag} size={28} />
                  </FooterNavItem>
                ))
              }
            </FooterNav>
          </Box>
        </Box>*/}
        
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  titleStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  newsletterButton: PropTypes.object,
  copyrightStyle: PropTypes.object,
  contactItem: PropTypes.object,
  flexBox: PropTypes.object,
  contactTitle: PropTypes.object,
  contactInfo: PropTypes.object,
  noMargin: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ml: '-15px',
    mr: '-15px',
    mb: ['0', '70px', '80px', '100px', '100px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3, 1 / 3],
    pl: '15px',
    pr: '15px',
    mb: ['40px', '0', '0', '0', '0', '0'],
  },
  titleStyle: {
    fontSize: ['16px', '18px'],
    fontWeight: '600',
    mb: ['20px', '25px'],
  },
  linkStyle: {
    fontSize: ['22px', '26px', '26px', '30px'],
    color: '#3444f1',
    mb: 0,
  },
  newsletterButton: {
    type: 'button',
    fontSize: '16px',
    pl: '20px',
    pr: '20px',
    colors: 'primary',
    minHeight: 'auto',
  },
  copyrightStyle: {
    fontSize: '14px',
    color: '#fff',
  },
  flexBox: {
    flexBox: true,
    justifyContent: 'space-between',
    // flexWrap: 'wrap'
  },
  contactItem: {
    // width: 1 / 2
  },
  contactTitle: {
    fontSize: ['15x', '15px', '16px', '16px', '16px'],
    mb: '10px',
  },
  contactInfo: {
    fontSize: ['16x', '16px', '18px', '18px', '20px'],
    fontWeight: '500',
    mb: 0,
  },
  noMargin: {
    mb: '0',
  },
};

export default Footer;
