import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import { Icon } from 'react-icons-kit';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';

import {
  SkillItem,
  SkillDetails,
  SkillProgress,
  SuccessRate,
  ProgressBar,
  SkillIcon,
  SkillAbout,
} from './skill.style';
import { ic_thumb_up } from 'react-icons-kit/md/ic_thumb_up';

import { useTranslation } from 'react-i18next'
import SkillIcon1 from 'common/src/assets/image/portfolio/skill-1.svg';
import SkillIcon2 from 'common/src/assets/image/portfolio/skill-2.svg';
import SkillIcon3 from 'common/src/assets/image/portfolio/skill-3.svg';
import SkillIcon4 from 'common/src/assets/image/portfolio/skill-4.svg';

const SkillSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  row,
  col,
  skillTitle,
  skillDescription,
  skillSuccessRate,
  successRateText,
}) => {
  
  const {t} = useTranslation();

  const BLOCKS = [
    {
      title: t("blocks.cell1.title"),
      description: t("blocks.cell1.content"),
      icon: SkillIcon1,
      successRate: '100',
    },
    {
      title: t("blocks.cell2.title"),
      description: t("blocks.cell2.content"),
      icon: SkillIcon2,
      successRate: '100',
    },
    {
      title: t("blocks.cell3.title"),
      description: t("blocks.cell3.content"),
      icon: SkillIcon3,
      successRate: '100',
    },
    {
      title: t("blocks.cell4.title"),
      description: t("blocks.cell4.content"),
      icon: SkillIcon4,
      successRate: '100',
    },
  ];

  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content={t('blocks.title')}/>
          <Text
            {...secDescription}
            content={t('blocks.subtitle')}
          />
        </Box>

        <Box {...row}>
          {BLOCKS.map((item, index) => (
            <Box {...col} key={`skill-item-${index}`} id={`skill-item-${index}`}>
              <SkillItem>
                <SkillDetails>
                  <SkillIcon>
                    <Image src={item.icon} alt={`skill-icon-${index + 1}`} />
                  </SkillIcon>
                  <SkillAbout>
                    <Heading content={item.title} {...skillTitle} />
                    <Text content={item.description} {...skillDescription} />
                  </SkillAbout>
                </SkillDetails>
                
                {/*
                <SkillProgress>
                  <SuccessRate>
                    <Icon
                      icon={ic_thumb_up}
                      size={12}
                      className="skill_success_icon"
                    />
                    <Text
                      as="span"
                      content={`${item.successRate}% `}
                      {...skillSuccessRate}
                    />
                    <Text
                      as="span"
                      content="Success Rate"
                      {...skillSuccessRate}
                      {...successRateText}
                    />
                  </SuccessRate>
                  
                  <ProgressBar>
                    <Line
                      percent={item.successRate}
                      strokeWidth="1.8"
                      trailWidth="1.8"
                      strokeColor="#3444f1"
                      trailColor="#e3e7f2"
                    />
                  </ProgressBar>
                </SkillProgress>
                */}

              </SkillItem>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

SkillSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  skillTitle: PropTypes.object,
  skillDescription: PropTypes.object,
  skillSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
};

SkillSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '140px'],
    pb: ['150px', '160px', '160px', '180px', '210px'],
    bg: '#f9f9f9',
  },
  secTitleWrapper: {
    mb: ['65px', '65px', '80px', '90px', '105px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'center',
    width: '600px',
    maxWidth: '100%',
    ml: 'auto',
    mr: 'auto',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-15px', '-15px', '-15px', '-25px', '-25px'],
    mr: ['-15px', '-15px', '-15px', '-25px', '-25px'],
  },
  col: {
    width: [1, 1, 1 / 2],
    pl: ['15px', '15px', '15px', '25px', '25px'],
    pr: ['15px', '15px', '15px', '25px', '25px'],
    mb: ['30px', '30px', '30px', '50px', '50px'],
  },
  skillTitle: {
    fontSize: ['16px', '18px', '18px', '20px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '12px',
  },
  skillDescription: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  skillSuccessRate: {
    fontSize: ['15px', '15px', '14px', '15px', '16px'],
    fontWeight: '400',
    color: '#302b4e',
    lineHeight: '1.5',
    mb: '0',
  },
  successRateText: {
    ml: '.3em',
    display: ['none', 'none', 'none', 'none', 'inline-block'],
  },
};

export default SkillSection;
