import React from 'react';
import { calendar } from 'react-icons-kit/ionicons/calendar';
import { person } from 'react-icons-kit/ionicons/person';
import Icon from 'react-icons-kit';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom'
import { decode } from 'he';
import { useTranslation } from 'react-i18next'
import './style.scss';

const BLOG_DESCRIPTION_LENGTH = 400;

function BlogPost({post, history}){
  const { t, i18n } = useTranslation();

  var content = post.content.replace(/<[^>]*>?/gm, '');
  content     = decode(content); 
  content     = content.length>BLOG_DESCRIPTION_LENGTH?content.substring(0,BLOG_DESCRIPTION_LENGTH)+'...': content;
  
  return <div onClick={() => history.push(`/blog/posts/${post.slug}`)} className='blog_post card'>
    <div className='blog_image' style={{backgroundImage:`url('${post.picture}')`}}/>
    
    <div className='blog_content'>
      <h3>{post.title}</h3>
      <p>{content}</p>

      <div className="blog_meta">
        <span className='blog_date'> <Icon icon={calendar} size={18}/> {moment(post.created).format('DD/MM/YYYY HH:mm')}</span><br/>
        <span className='blog_author'><Icon icon={person} size={18}/> { t("blog.author") } {post.author.first_name} {post.author.last_name}</span>
      </div>

    </div>
  </div>
}

export default withRouter(BlogPost)