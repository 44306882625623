export function toParams(query) {
  const q = query.replace(/^\??\//, '');

  return q.split('&').reduce((values, param) => {
    const [key, value] = param.split('=');

    values[key] = value;

    return values;
  }, {});
}

export function toQuery(params, delimiter = '&') {
  const keys = Object.keys(params);

  return keys.reduce((str, key, index) => {
    let query = `${str}${key}=${params[key]}`;

    if (index < (keys.length - 1)) {
      query += delimiter;
    }

    return query;
  }, '');
}

export function getOrientation(file, callback) {
    var reader = new FileReader();
    reader.onload = function(e) {

        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) !== 0xFFD8)
        {
            return callback(-2);
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) 
        {
            if (view.getUint16(offset+2, false) <= 8) return callback(-1);
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker === 0xFFE1) 
            {
                if (view.getUint32(offset += 2, false) !== 0x45786966) 
                {
                    return callback(-1);
                }

                var little = view.getUint16(offset += 6, false) === 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++)
                {
                    if (view.getUint16(offset + (i * 12), little) === 0x0112)
                    {
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            }
            else if ((marker & 0xFF00) !== 0xFF00)
            {
                break;
            }
            else
            { 
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };
    reader.readAsArrayBuffer(file);
}

export function orientate(file_to_orientate, callback){
    getOrientation(file_to_orientate,orientation=>{
        if ( orientation < 2 || orientation > 8 ) return callback(file_to_orientate); //Do nothing
        var reader = new FileReader();
        reader.onload = function (e1) {
            const imgB64 = e1.target.result;
            
            var img = new Image();    

            img.onload = function() {
                var width = img.width,
                    height = img.height,
                    canvas = document.createElement('canvas'),
                    ctx = canvas.getContext("2d");

                // set proper canvas dimensions before transform & export
                if (4 < orientation && orientation < 9) {
                  canvas.width = height;
                  canvas.height = width;
                } else {
                  canvas.width = width;
                  canvas.height = height;
                }
                // transform context before drawing image
                switch (orientation) {
                  case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                  case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                  case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                  case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                  case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                  case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                  case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                  default: break;
                }
                // draw image
                ctx.drawImage(img, 0, 0);
                ctx.canvas.toBlob( blob=>{
                    const file = new File([blob], file_to_orientate.name, { type: 'image/jpeg',lastModified: Date.now() });
                    callback(file);
                }, 'image/jpeg', 1);
            };
            img.src = imgB64;
        };
        reader.readAsDataURL(file_to_orientate);
    });
}

export function compress(file_to_compress, callback, MAX_SIZE = 1000000) { //1MB is max size
    if( file_to_compress.size < MAX_SIZE ) return callback(file_to_compress);
    
    const width = 1024; //Height is maintained by ratio
    const reader = new FileReader();
    
    reader.readAsDataURL(file_to_compress);
    
    reader.onload = event => {
        const img = new Image();
        img.src = event.target.result;
        
        img.onload = param => {
            const elem = document.createElement('canvas');
            
            elem.width  = width;
            elem.height = img.height*(width/img.width); //Maintain aspect ratio

            const ctx = elem.getContext('2d');
            // img.width and img.height will contain the original dimensions
            ctx.drawImage(img, 0, 0, elem.width, elem.height);
            ctx.canvas.toBlob((blob) => {
                const file = new File([blob], file_to_compress.name, { type: 'image/jpeg',lastModified: Date.now() });
                callback(file);
            }, 'image/jpeg', 1);
        }

        reader.onerror = error => console.log(error);
    };
}

