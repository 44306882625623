import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { connect } from 'react-redux';
import { PrevButton, NextButton } from '../portfolio.style';
import {
  AwardSectionWrapper,
  AwardItem,
  AwardeeWrapper,
  AwardeeLogo,
  AwardeeDetails,
  AwardImageWrapper,
} from './awards.style';

import Awardee1 from 'common/src/assets/image/portfolio/awardee-1.png';

import Image1 from 'common/src/assets/image/eteria/featured/red_tree.png';
import Image2 from 'common/src/assets/image/eteria/featured/patio77.jpg';
import Image3 from 'common/src/assets/image/eteria/featured/casa_pancha.jpg';
import Image4 from 'common/src/assets/image/eteria/featured/mia_cogrow.jpg';

// import AwardImage1 from 'common/src/assets/image/eteria/featured/red_tree.png';
// import AwardImage1 from 'common/src/assets/image/eteria/featured/red_tree.png';
// import AwardImage1 from 'common/src/assets/image/eteria/featured/red_tree.png';

import { useTranslation } from 'react-i18next'
import { updateMapPlace } from '../../../actions'

const AwardsSection = ({
  secTitleWrapper,
  secTitle,
  secDescription,
  awardLogoStyle,
  awardNameStyle,
  awardDetailsStyle,
  awardeeLogoStyle,
  awardeeNameStyle,
  awardDateStyle,
  updateMapPlace
}) => {
  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    autoplay: 8000,
    perView: 4,
    gap: 30,
    animationDuration: 2000,
    breakpoints: {
      990: {
        perView: 3,
      },
      767: {
        perView: 2,
      },
      500: {
        perView: 1,
      },
    },
  };
  
  const { t } = useTranslation();

  const FEATURED = [
    {
      awardLogo: Image1,
      
      awardName: 'Red Tree House',
      awardDetails: t('featured.f1.detail'),
      url:'http://theredtreehouse.com/',

      awardeeLogo: Awardee1,
      awardeeName: 'Awardee',
      date: 'The Jury 2019',
      google_id:'ChIJhfXaxUH_0YURhTDCWYCNHUs',
    },
    {
      awardLogo: Image2,

      awardName: 'Patio 77',
      awardDetails: t('featured.f2.detail'),
      url:'https://www.elpatio77.com/',
      
      awardeeLogo: Awardee1,
      awardeeName: 'Awardee',
      date: 'The Jury 2019',

      google_id:'ChIJKcoG6Mj40YURnL8AXbAemcA',
    },
    {
      awardLogo: Image3,

      awardName: 'Casa Pancha',
      awardDetails: t('featured.f3.detail'),
      url:'https://casapancha.com/',
      
      awardeeLogo: Awardee1,
      awardeeName: 'Awardee',
      date: 'The Jury 2019',

      google_id:'ChIJVVXxOV3_0YURIWfk2L22bCk',
    },
    {
      awardLogo: Image4,

      awardName: 'Mía Co-Growing',
      awardDetails: t('featured.f4.detail'),
      url:'https://miacogrowing.com/',
      
      awardeeLogo: Awardee1,
      awardeeName: 'Awardee',
      date: 'The Jury 2019',

      google_id:'ChIJewdEIpMB0oURBo6ht4EvGB8',
    },

  ];

  return (
    <AwardSectionWrapper id="awards_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading
            {...secTitle}
            content={t('featured.title')}
          />
          <Text
            {...secDescription}
            content={t('featured.subtitle')}
          />
        </Box>

        <GlideCarousel
          carouselSelector="awards-carousel"
          options={carouselOptions}
          prevButton={
            <PrevButton>
              <span />
            </PrevButton>
          }
          nextButton={
            <NextButton>
              <span />
            </NextButton>
          }
        >
          <>
            {FEATURED.map((award, index) => (
              <GlideSlide key={`award-item-${index}`} >
                <AwardItem>
                  <AwardImageWrapper>
                    <Image
                      src={award.awardLogo}
                      alt={`award-logo-${index}`}
                      {...awardLogoStyle}/>
                  </AwardImageWrapper>
                  <Heading content={award.awardName} {...awardNameStyle} />
                  <Text className="featured-text justify-content" content={award.awardDetails} {...awardDetailsStyle}/>
                  
                  <div style={{position:'absolute', textAlign: 'center', left:'50%', transform:'translate(-50%,0)', bottom:"25px"}}>
                    <a href={award.url} target="_blank" className="cta_map">{t('featured.cta')}</a>
                  </div>
                  
                  {/*
                  <AwardeeWrapper>
                    <AwardeeLogo>
                      <Image
                        src={award.awardeeLogo}
                        alt={`awardee-logo-${index}`}
                        {...awardeeLogoStyle}
                      />
                    </AwardeeLogo>
                    <AwardeeDetails>
                      <Heading
                        content={award.awardeeName}
                        {...awardeeNameStyle}
                      />
                      <Text content={award.date} {...awardDateStyle} />
                    </AwardeeDetails>
                  </AwardeeWrapper>
                  */}

                </AwardItem>

              </GlideSlide>
            ))}
          </>
        </GlideCarousel>
      </Container>
    </AwardSectionWrapper>
  );
};

AwardsSection.propTypes = {
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  awardLogoStyle: PropTypes.object,
  awardNameStyle: PropTypes.object,
  awardDetailsStyle: PropTypes.object,
  awardeeLogoStyle: PropTypes.object,
  awardeeNameStyle: PropTypes.object,
  awardDateStyle: PropTypes.object,
};

AwardsSection.defaultProps = {
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: '90px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  awardLogoStyle: {
    ml: 'auto',
    mr: 'auto',
    mb: '25px',
  },
  awardNameStyle: {
    fontSize: ['16px', '16px', '18px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    lineHeight: '1.35',
    textAlign: 'center',
    mb: '17px',
  },
  awardDetailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    textAlign: 'center',
    mb: '0',
  },
  awardeeNameStyle: {
    fontSize: '16px',
    color: '#9391a5',
    lineHeight: '1.35',
    fontWeight: '600',
    mb: '4px',
  },
  awardDateStyle: {
    fontSize: '12px',
    color: '#9391a5',
    lineHeight: '1.35',
    mb: '0',
  },
};
// TO DO

export default connect(null,{ updateMapPlace })(AwardsSection);
