import React from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';

import { PrevButton, NextButton } from '../portfolio.style';
import {
  TestimonialWrapper,
  TestimonialItem,
  TestimonialHead,
  TestimonialThumb,
} from './testimonial.style';

import { twitter } from 'react-icons-kit/icomoon/twitter';
import { instagram } from 'react-icons-kit/icomoon/instagram';

import Reviewer1 from 'common/src/assets/image/eteria/testimonies/1.png';
import Reviewer2 from 'common/src/assets/image/eteria/testimonies/2.png';
import Reviewer3 from 'common/src/assets/image/eteria/testimonies/3.png';
import Reviewer4 from 'common/src/assets/image/eteria/testimonies/4.png';
import Reviewer5 from 'common/src/assets/image/eteria/testimonies/5.png';
import Reviewer6 from 'common/src/assets/image/eteria/testimonies/6.png';
import Reviewer7 from 'common/src/assets/image/eteria/testimonies/7.png';
import Reviewer8 from 'common/src/assets/image/eteria/testimonies/8.png';
import Reviewer9 from 'common/src/assets/image/eteria/testimonies/9.png';

import { useTranslation } from 'react-i18next'

const TestimonialSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  reviewStyle,
  nameStyle,
  designationStyle,
}) => {
  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    autoplay: 6000,
    perView: 3,
    gap: 28,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 3,
      },
      767: {
        perView: 2,
      },
      500: {
        perView: 1,
      },
    },
  };

  const { t } = useTranslation();

  const TESTIMONIAL = [
    {
      image: Reviewer1,
      review:t("testimony.card1.content"),
      designation: t("testimony.card1.designation"),
      name: 'Mallik Vora',
      externalProfile: 'https://www.instagram.com/p/B2xdUMqnACW/?igshid=pzx66i5892p1',
      icon: instagram,
    },
    {
      image: Reviewer2,
      review:t("testimony.card2.content"),
      designation: t("testimony.card2.designation"),
      name: 'Montse Arce',
      externalProfile: 'https://www.instagram.com/p/B3D1pmanWvk/?igshid=1mp04hgsdylw6',
      icon: instagram,
    },
    {
      image: Reviewer3,
      review:t("testimony.card3.content"),
      designation: t("testimony.card3.designation"),
      name: 'Ellen Freeman',
      externalProfile: 'https://www.instagram.com/p/B3mhOhpDXPB/?igshid=6rkkrrkppnkr',
      icon: instagram,
      organization: 'Broccolli Magazine',
      organizationURL: 'https://www.instagram.com/broccoli_mag/',
    },
    {
      image: Reviewer4,
      review:t("testimony.card4.content"),
      designation: t("testimony.card4.designation"),
      name: 'Bita Aranda',
      externalProfile: 'https://www.instagram.com/p/B3UvAHOjvyW/?igshid=6pzmp9e47dc7',
      icon: instagram,
      organization: 'Girl Up Campaign',
      organizationURL: 'https://www.instagram.com/girlupcampaign/',
    },
    {
      image: Reviewer5,
      review:t("testimony.card5.content"),
      designation: t("testimony.card5.designation"),
      name: 'Hannah Gittleman',
      externalProfile: 'https://www.instagram.com/p/B3UvAHOjvyW/?igshid=6pzmp9e47dc7',
      icon: instagram,
      organization: 'One Way Wander',
      organizationURL: 'https://www.instagram.com/onewaywander_/',
    },
    {
      image: Reviewer6,
      review:t("testimony.card6.content"),
      designation: t("testimony.card6.designation"),
      name: 'Corbin Lamont',
      externalProfile: 'https://www.instagram.com/p/B4dApwfjYWM/?igshid=qmyga35idrzo',
      icon: instagram,
      organization: 'The Changing Times',
      organizationURL: 'https://www.instagram.com/thechangingtimes/',
    },
    {
      image: Reviewer7,
      review:t("testimony.card7.content"),
      designation: t("testimony.card7.designation"),
      name: 'Sara Alashek',
      externalProfile: 'https://www.instagram.com/p/B4vzhNDjNBh/?igshid=185uxqnjo0c6m',
      icon: instagram,
      organization: 'Orggenera',
      organizationURL: 'https://www.instagram.com/orggenera/',
    },
    {
      image: Reviewer8,
      review:t("testimony.card8.content"),
      designation: t("testimony.card8.designation"),
      name: "Maria D'Flon",
      externalProfile: 'https://www.instagram.com/p/B72NBDajvWi/',
      icon: instagram,
    },
    {
      image: Reviewer9,
      review:t("testimony.card9.content"),
      designation: t("testimony.card9.designation"),
      name: 'Ceci Suarez',
      externalProfile: 'https://www.instagram.com/p/B8KoRwgD9sK/',
      icon: instagram,
    },
  ];


  return (
    <Box {...sectionWrapper} as="section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content={t("testimony.title")}/>
          <Text
            {...secDescription}
            content={t("testimony.subtitle")}
          />
        </Box>
        <TestimonialWrapper>
          <GlideCarousel
            carouselSelector="testimonial-carousel"
            options={carouselOptions}
            prevButton={
              <PrevButton>
                <span />
              </PrevButton>
            }
            nextButton={
              <NextButton>
                <span />
              </NextButton>
            }
          >
            <>
              {TESTIMONIAL.map((item, index) => (
                <GlideSlide key={`testimonial-item-${index}`}>
                  <TestimonialItem>
                    <TestimonialHead>
                      <TestimonialThumb>
                        
                        <Image
                          src={item.image}
                          alt={`testimonial-avatar-${index + 1}`}/>

                      </TestimonialThumb>
                      
                      <a target="_blank" href={item.externalProfile || '#'}>
                        <Icon icon={item.icon} size={24} />
                      </a>

                    </TestimonialHead>
                    <Text {...reviewStyle} content={item.review} />
                    <Heading as="h3" content={item.name} {...nameStyle} />
                    <Text
                      as="span"
                      content={item.designation}
                      {...designationStyle}
                    />
                    
                    <a target="_blank" href={item.organizationURL || '#'}   className="reviewer_org">{item.organization}</a>
                    
                  </TestimonialItem>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </TestimonialWrapper>
      </Container>
    </Box>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  reviewStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '100px', '110px', '150px'],
    pb: '50px',
  },
  secTitleWrapper: {
    mb: ['90px', '90px', '50px', '50px', '50px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    width: '530px',
    maxWidth: '100%',
    mb: '0',
  },
  reviewStyle: {
    fontSize: '16px',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '30px',
  },
  nameStyle: {
    fontSize: '16px',
    color: '#302b4e',
    fontWeight: '600',
    mb: '7px',
  },
  designationStyle: {
    fontSize: '14px',
    color: '#43414e',
    mb: '0',
  },
};

export default TestimonialSection;
