import React from 'react';
import { useTranslation } from 'react-i18next'

export default function Banner({
  title="ETERIA",
  backgroundImage="url('/assets/img/main_banner.png')",
  spaced=false,
  blogFix=false
}){
	const { t } = useTranslation()
    return <header className={`header ${blogFix?'header__blog-fix':''}`} style={{backgroundImage}}>
        {/*
        <div class="header__logo-box">
            <img class="header__logo" src="img/logo-white.png" alt="logo">
        </div>
        */}
        
        <div className="header__text-box">
            
            <h1 className="header__center">
                <span className={`header__text-primary ${spaced?'header__text-primary--spaced':''}`}>{title}</span>
                {/*<span className="header__text-secondary">{ t("banner.subtitle")}</span>
                <span className="header__text-tertiary">{ t("banner.subtitle3")}</span>
                <hr/>*/}
            </h1>

        </div>
    </header>
}