import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Portfolio/portfolio.style';

import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';
import LoginForm from '../components/login_form';

class Login extends React.Component {
  
  render () {
    return (
      <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Helmet>
          <title>Eteria | Women traveling the world</title>
          <meta name="Description" content="Eteria, creating a safer world for women by generating consciousness." />
        </Helmet>
        <div className="nav-fix"/>
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>

          <LoginForm/>

          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>);

  }
}

export default Login;