import axios from 'axios';
import { AUTH_LOCAL_KEY, PROD_API_LOCATION } from '../settings'
import {
	AUTH_USER,
	UNAUTH_USER,
	UPDATE_MAP_PLACE,
	CLEAN_MAP_PLACE
} from './types';

// axios.defaults.withCredentials = true;
const dev = window.location.hostname==="localhost" || window.location.port==="3000" ;
export const API_LOCATION = dev?`http://${window.location.hostname}:8888`:PROD_API_LOCATION;
axios.defaults.baseURL = API_LOCATION + '/api/v1';

export function signinUser( user ){
	localStorage.setItem(AUTH_LOCAL_KEY, JSON.stringify(user));
	axios.defaults.headers.common['Authorization'] = `Token ${user.token}`;
	return {type: AUTH_USER, payload: user };
}

export function signoutUser(){
	localStorage.removeItem(AUTH_LOCAL_KEY); //User
	axios.defaults.headers.common['Authorization'] = "";	
	return {
		type: UNAUTH_USER,
		payload: {}
	}
}

// The action gets the place info from the server
export function updateMapPlace(place_id, key='google_id'){
	return dispatch => {
		console.log(place_id)
		axios.get('places',  { params: { [key]: place_id } } ).then(({data:{results,count}})=>{
			console.log(results)
			dispatch({
				type:    UPDATE_MAP_PLACE,
				payload: count>0?results[0]:{}
			});
		}).catch(err=>{
			console.error(err);
		})
	}
}

// The action gets the place info from the server
export function cleanMapPlace(){
	return {
		type:    CLEAN_MAP_PLACE,
		payload: {}
	}
}